import {
    ClassicEditor,
    Essentials,
    Bold,
    Italic,
    Underline,
    Font,
    Paragraph,
    Link,
    List,
    Alignment,
    Indent,
    HtmlEmbed,
    Image,
    ImageUpload,
    ImageInsert,
    CKFinder,
    CKFinderUploadAdapter,
    BlockQuote,
    MediaEmbed,
    Table,
    SourceEditing,
    Notification,
    ImageCaption,
    LinkImage,
    ImageToolbar
} from "ckeditor5";
import coreTranslations from "ckeditor5/translations/pt-br.js";

/**
 * 
 * @param {HTMLElement} element 
 * @param {import("ckeditor5").EditorConfig} config 
 */
export async function initClassicEditor(element, config = {}){
    try {
        const editor = await ClassicEditor.create(element, {
            ...config,
            plugins: [
                Essentials, 
                Bold, 
                Italic, 
                Font, 
                Paragraph,
                Underline,
                Link,
                List,
                Alignment,
                Indent,
                HtmlEmbed,
                Image,
                ImageUpload,
                ImageInsert,
                CKFinder,
                CKFinderUploadAdapter,
                BlockQuote,
                MediaEmbed,
                Table,
                SourceEditing,
                Notification,
                ImageCaption,
                LinkImage,
                ImageToolbar
            ],
            ckfinder: {
                uploadUrl: root + '/ck/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
            },
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'underline',
                    'link',
                    '|',
                    'fontFamily',
                    'fontSize',
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'numberedList',
                    'bulletedList',
                    '|',
                    'alignment',
                    'outdent',
                    'indent',
                    '|',
                    'htmlEmbed',
                    'imageUpload',
                    'blockQuote',
                    'insertTable',
                    'mediaEmbed',
                    'undo',
                    'redo',
                    'sourceEditing'
                ]
            },
            language: 'pt-br',
            image: {
                toolbar: [
                    'toggleImageCaption',
                    '|',
                    'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
                    '|',
                    'resizeImage',
                    '|',
                    'imageTextAlternative'
                ],
                resizeOptions: [
                    {
                        name: 'resizeImage:original',
                        label: 'Original',
                        value: null
                    },
                    {
                        name: 'resizeImage:50',
                        label: '50%',
                        value: '50'
                    },
                    {
                        name: 'resizeImage:75',
                        label: '75%',
                        value: '75'
                    }
                ],
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells'
                ]
            },
            licenseKey: '',
            translations: [coreTranslations]
        });

        const sourceEditor = $(editor.sourceElement);
        const required = sourceEditor.attr('required') ? true : false;
        const ckeditorObj = $(editor.ui.view.element);

        ckeditorObj.attr('required', required);
        sourceEditor.attr('required', false);

        return editor;
    } catch(error){
        console.error('Oops, something went wrong!');
        console.error('Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:');
        console.warn('Build id: yuwz89snko0l-a8vx8hv2dlce');
        console.error(error);
    }
}